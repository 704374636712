import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

import CheeseProduct from '../Assets/Images/Web/graxalta_GxD_jpg_webdesign2021_productillustrations_lacteos_02.jpg'
import MushroomProduct from '../Assets/Images/Web/graxalta_GxD_jpg_webdesign2021_productillustrations_hongos_01.jpg'
import FruitProduct from '../Assets/Images/Web/graxalta_GxD_jpg_webdesign2021_productillustrations_fresas_01.jpg'

const Wrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  background-color: ${props => props.theme.colors.CustomWhite};
`

const WrapperTitle = styled.div`
  display: inherit;
  height: 80px;
  align-items: center;
  justify-content: center;
  
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-bottom: 50px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    margin-bottom: 20px;
  }
`

const Title = styled.div`
  font-size: ${props => props.theme.fontSizes.xlarge};
  font-family: ${props => props.theme.fonts[1]};
  color: ${props => props.theme.colors.CustomBlue};
  padding: 0 10px;
`

const GreenLine = styled.div`
  border-top: 2px solid ${props => props.theme.colors.CustomGreen};
  width: 40px;
  margin-bottom: 3px;
`

const FirstWrapper = styled.div`
  width: 50%;
  display: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-bottom: 0;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    margin-bottom: 20px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 0;
    width: 80%;
    flex-direction: column;
  }
`

const FirstReverseWrapper = styled.div`
  width: 50%;
  display: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-bottom: 0;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    margin-bottom: 20px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 0;
    width: 80%;
    flex-direction: column-reverse;
  }
`

const SecondWrapper = styled.div`
  width: 50%;
  display: inherit;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-bottom: 0;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    margin-bottom: 20px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 0;
    width: 80%;
    flex-direction: column;
  }
`

const ProductImg = styled.img`
  width: 50%;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
  }
`

const TextContainer = styled.div`
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.CustomGray};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 50%;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 45%;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
  }
`

const TextContainerLeft = styled(TextContainer)`
    /*BIG SCREEN > LAPTOPS / DESKTOPS */
    @media (min-width: 1025px){
    width: 45%;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 40%;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
  }
`

const TitleContainer = styled.div`
  text-align: left;
  font-size: ${props => props.theme.fontSizes.large};;
  font-family: ${props => props.theme.fonts[1]};
  color: ${props => props.theme.colors.CustomBlue};
  padding: 30px 0;
`

const FooterContainer = styled.div`
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.CustomBlue};
  padding: 30px 0;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 80%;
    padding: 30px 0;
  }
`

const LinkText = styled.span`
  color: ${props => props.theme.colors.CustomBlue};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.medium};
  text-decoration: none !important;
  cursor: pointer;
`

const LinkAnchor = styled.a`
  color: ${props => props.theme.colors.CustomGreen};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.medium};
  cursor: pointer;
  text-decoration: none !important;
`

const LinkAnchorBlue = styled.a`
  color: ${props => props.theme.colors.CustomBlue};
  font-family: ${props => props.theme.fonts[1]};
  text-decoration: none !important;
  font-size: ${props => props.theme.fontSizes.medium};
  cursor: pointer;
`

export const ProductSection = (props) => {
  const [display, setDisplay] = useState(<></>)


  useEffect(() => {
    switch (props.knownUser.request) {
      case 'supplier':
        setDisplay(
          <>
            {' '}
            <LinkText><Link to="contact-us" smooth={true} offset={-150}>{' '}contáctenos</Link></LinkText>
          </>
        )
        break;
      case 'wholesaler':
        setDisplay(
          <>
            {' '}
            <LinkAnchorBlue href="https://l.wl.co/l?u=https%3A%2F%2Fbit.ly%2FDeliveryGraxalta" target="_blank" rel="noreferrer">contactenos.</LinkAnchorBlue>
          </>
        )
        break;
      case 'customer':
        setDisplay(
          <>
            <LinkText style={{ fontSize: '12px' }}><Link to="contact-us" smooth={true} offset={-150}>{' '}contáctenos</Link></LinkText>
            {' '}o realice {' '}
            <LinkAnchor href="https://l.wl.co/l?u=https%3A%2F%2Fbit.ly%2FDeliveryGraxalta" target="_blank" rel="noreferrer">su pedido aquí.</LinkAnchor>
          </>
        )
        break;
      default:
        break;
    }
  }, [props.knownUser.request])


  return (
    <Wrapper id="product">
      <WrapperTitle>
        <GreenLine />
        <Title>Nuestros Productos</Title>
        <GreenLine />
      </WrapperTitle>
      <FirstWrapper>
        <ProductImg src={CheeseProduct} alt="Queso" />
        <TextContainer><TitleContainer>Quesos de Búfala</TitleContainer>Nos sentimos orgullosos de tener una sólida relación comercial con la compañía Hato El Oso, C.A. productores de la marca de quesos y derivados lácteos, Bufalinda, 100% leche de Búfala. Semanalmente recibimos desde Barrancas, Edo Monagas, queso Mozarella, queso Bococcini, queso Ricotta, queso Feta, yogurt El Griego y más. Graxalta comercializa toda la gama de productos Bufalinda, en sus distintas presentaciones.</TextContainer>
      </FirstWrapper>
      <SecondWrapper>
        <TextContainerLeft><TitleContainer>Quesos de Cabra</TitleContainer>Por más de 10 años, Graxalta ha mantenido una relación comercial con la Procesadora de Lácteos Las Palmitas C.A. Graxalta se ha convertido en uno de los representantes principales de la marca de queso Las Cumbres en el área metropolitana de Caracas.</TextContainerLeft>
        <TextContainer><TitleContainer>Quesos</TitleContainer>Graxalta cuenta con una relación firme con la marca Flor de Aragua, quienes ofrecen gran variedad de productos lácteos dentro del mercado Venezolano.</TextContainer>
      </SecondWrapper>
      <FooterContainer>Para más información sobre productos y disponibilidad
      {display}
      </FooterContainer>
      <br />
      <br />
      <br />
      <br />
      <FirstReverseWrapper>
        <TextContainer>
          <TitleContainer>Hongos Cultivados</TitleContainer>
          Mantenemos una excelente relación comercial con las mejores y más estables plantas champiñoneras del país. Esto nos permite distribuir en forma constante los tres tipos de Hongos que se cultivan en Venezuela; el tradicional Champiñón u Hongo de París, el Portobello y sus variantes más pequeñas Portobellinis y Creminis, y las Setas u Hongo Oyster (Pleurotus). Recibimos mercancía varias veces a la semana directamente en nuestro depósito, lo que garantiza frescura y disponibilidad.
          <FooterContainer>Para más información sobre productos y disponibilidad
          {display}
          </FooterContainer>
        </TextContainer>
        <ProductImg src={MushroomProduct} alt="Queso" />
      </FirstReverseWrapper>
      <br />
      <br />
      <br />
      <br />
      <FirstWrapper>
        <ProductImg src={FruitProduct} alt="Queso" />
        <TextContainer>
          <TitleContainer>Frutas Congeladas</TitleContainer>
          Excelentes frutas congeladas, cultivadas y procesadas en Boconó y Mérida.
          <FooterContainer>Para más información sobre productos y disponibilidad
          {display}
          </FooterContainer>
        </TextContainer>
      </FirstWrapper>
    </Wrapper>
  )
}