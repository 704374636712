import React, { useState } from 'react'
import styled from 'styled-components'
import { ImWhatsapp } from 'react-icons/im';
import ReactPixel from 'react-facebook-pixel';

import { Header } from '../Components/Header'
import { VideoSection } from '../Components/VideoSection'
import { AboutSection } from '../Components/AboutSection'
import { ProductSection } from '../Components/ProductSection'
import { ContactSection } from '../Components/ContactSection'
import { Footer } from '../Components/Footer'

const Wrapper = styled.div`
`

const OverScreen = styled.div`
  display: ${props => props.isShown ? "block" : "none"};
  z-index: 100; /* Sit on top */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.95;
  margin: auto;
  padding: 60px;
  background-color: ${props => props.theme.colors.CustomBlue};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    padding: 100px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    padding: 60px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 30px;
  }
`

const OverContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    height: 80vh;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    height: 80vh;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    height: 90vh;
  }
`

const Text = styled.div`
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.CustomWhite};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 50%;
    margin-bottom: 3px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 50%;
    margin-bottom: 3px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 90%;
    margin-bottom: 3px;
  }
`

const TextTitle = styled.div`
  font-size: ${props => props.theme.fontSizes.large};
  color: ${props => props.theme.colors.CustomWhite};
  display: flex;
  justify-content: center;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-top: 50px;
    margin-bottom: 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 40%;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 80%;
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

const ButtonWrapper = styled.div`
  display: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 45%;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 65%;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 95%;
  }
`

const ButtonForm = styled.button`
  border: none;
  border-radius: 33px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  color: ${props => props.theme.colors.CustomWhite};
  background-color: ${props => props.theme.colors.CustomGreen};

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 15px;
    padding: 10px 15px;
    margin: 10px 0;
    width: 60%;
    height: 40px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    font-size: 15px;
    padding: 5px 10px;
    margin: 10px 0;
    width: 55%;
    height: 35px;
  }

  @media (max-width: 760px){
    font-size: 15px;
    padding: 5px 10px;
    margin: 10px 0;
    width: 100%;
    height: 45px;
  }
`

const WhatsappButton = styled.a`
  position:fixed;	
	bottom:40px;
	right:60px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  
	box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    font-size: 1.8rem;
    width: 60px;
    height: 60px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1125px){
    font-size: 1.4rem;
    width:56px;
    height:56px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    display: none;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    font-size: 1.3rem;
    width: 50px;
    height: 50px;
    right: 25px;
  }
`

const WhiteLine = styled.div`
  background-color: white;
  height: 1px;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 60%;
    margin: 15px 0;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 55%;
    margin: 10px 0;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
  }
`

export const Home = () => {
  const [visibility, setVisibility] = useState(true)
  const [initInfo, setInitInfo] = useState({})

  const HandlerButtons = e => {
    const info = {
      request: e.target.id
    }
    setInitInfo(info)
    setVisibility(false)
  }

  const WhatsAppButton = () => {
    ReactPixel.track('Contact', {
      content_name: "Home"
    })
  }

  return (
    <Wrapper>
      <OverScreen isShown={visibility}>
        <OverContent>
          <TextTitle>Por favor, elija una de las siguientes opciones:</TextTitle>
          <ButtonWrapper>
            <ButtonForm id="supplier" name="supplier" onClick={HandlerButtons}>Proveedor</ButtonForm>
            <WhiteLine />
            <ButtonForm id="wholesaler" name="wholesaler" onClick={HandlerButtons}>Comercio</ButtonForm>
            <Text>Automercados, Restaurantes, Bodegones, Foodtrucks, Fruterias.</Text>
            <ButtonForm id="customer" name="customer" onClick={HandlerButtons}>Delivery/Pick-up</ButtonForm>
            <Text>Persona natural.</Text>
          </ButtonWrapper>
        </OverContent>
      </OverScreen>
      <Header knownUser={initInfo} />
      <VideoSection />
      <AboutSection knownUser={initInfo} />
      <ProductSection knownUser={initInfo} />
      <ContactSection knownUser={initInfo} />
      <Footer />
      <WhatsappButton onClick={WhatsAppButton} href="https://wa.me/+584242236075" target="_blank">
        <ImWhatsapp />
      </WhatsappButton>
    </Wrapper>
  )
}