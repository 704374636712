import React from 'react'
import styled from 'styled-components'
import ReactPixel from 'react-facebook-pixel';

import FooterBackground from '../Assets/Images/Web/graxalta_GxD_jpg_webdesign2021_footerillustrations_03.jpg'
import FacebookIcon from '../Assets/Images/Web/grax_facebook.png'
import InstagramIcon from '../Assets/Images/Web/grax_instagram_2.png'
import LinkedingIcon from '../Assets/Images/Web/grax_linkedin.png'
import WhatsAppIcon from '../Assets/Images/Web/grax_whatsapp.png'

// const FooterImg = styled.img`
//   width: 100%;

//   /*BIG SCREEN > LAPTOPS / DESKTOPS */
//   @media (min-width: 1025px){
//     margin-bottom: 80px;
//   }

//   /*BIG SCREEN > LAPTOPS / DESKTOPS */
//   @media (max-width: 1425px){
//     margin-bottom: 40px;
//   }

//   /* SMALL SCREEN > MOBILE */
//   @media (max-width: 760px){
//     height: auto;
//     margin-bottom: 20px;
//   }
// `

const WrapperFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  background-image: url(${FooterBackground}); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 360px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  margin-bottom: 80px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 0px;
  }
`

const TextFooter = styled.div`
  font-size: ${props => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.CustomWhite};
  text-align: center;
  line-height: 25px;
`

const LinkAnchor = styled.a`
  font-size: ${props => props.theme.fontSizes.medium};
  color: ${props => props.theme.colors.CustomWhite};
  text-align: center;
  line-height: 25px;
  cursor: pointer;
`

const SocialIcons = styled.img`
  width: 42px;
  margin: 30px 10px;
`

export const Footer = () => {

  const WhatsAppButton = () => {
    ReactPixel.track('Contact', {
      content_name: "Footer"
    })
  }

  return (
    <WrapperFooter>
      <TextFooter>
        <div>Inversiones Graxalta C.A.</div>
        <div>J-300072762</div>
        <div>E-mail: ventas@graxalta.com.ve</div>
        <div>Teléfono: +58 212 2833806</div>
        {/* <div>WhatsApp: <LinkAnchor href="https://l.wl.co/l?u=https%3A%2F%2Fbit.ly%2FDeliveryGraxalta" target="_blank" rel="noreferrer">+58 424 2236075</LinkAnchor></div> */}
        <div>
          <LinkAnchor onClick={WhatsAppButton} href="https://wa.me/+584242236075" target="_blank" rel="noreferrer">
            <SocialIcons src={WhatsAppIcon} alt='WhatsApp' />
          </LinkAnchor>
          <LinkAnchor href="https://www.facebook.com/Graxalta-107385941409433" target="_blank" rel="noreferrer">
            <SocialIcons src={FacebookIcon} alt='Facebook' />
          </LinkAnchor>
          <LinkAnchor href="https://www.instagram.com/graxalta/?hl=es" target="_blank" rel="noreferrer">
            <SocialIcons src={InstagramIcon} alt='Instagram' />
          </LinkAnchor>
          <LinkAnchor href="https://www.linkedin.com/company/graxalta/" target="_blank" rel="noreferrer">
            <SocialIcons src={LinkedingIcon} alt='Linkedin' />
          </LinkAnchor>
        </div>
      </TextFooter>

    </WrapperFooter>
  )
}