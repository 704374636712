import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from 'react-scroll'

import Logo from '../Assets/Images/Web/graxalta_GxD_png_cleanlogo_01.jpg'
import { SideMenu } from './SideMenu'


const Wrapper = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  height: 130px;
  background-color: ${props => props.theme.colors.CustomWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 6px #0000001F;

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    ${'' /* background-color: aqua; */}
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    ${'' /* background-color: antiquewhite; */}
  }

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    ${'' /* background-color: blueviolet; */}
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    height: 90px;
  }
`

const LeftWrapper = styled.div`
  width: 100%;
  display: inherit;
  align-items: center;
  justify-content: left;
  padding-left: 60px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    justify-content: flex-end;
    padding-left: 0px;
    margin-right: 40px;
  }
`

const RightWrapper = styled.div`
  width: 100%;
  display: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 60px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`

const LogoHeader = styled.img`
  cursor: pointer;

 /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    width: 30%;
    height: 30px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 35%;
    height: 25px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: auto;
    height: 18px;
  }
`

const MenuOption = styled.div`
  color: ${props => props.theme.colors.CustomBlue};
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.xlarge};
  &:hover {
    color: ${props => props.theme.colors.CustomGreen};
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    padding: 10px 50px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    padding: 10px 30px;
  }
`

const MenuOptionAnchor = styled.a`
  color: ${props => props.theme.colors.CustomBlue};
  text-decoration: none;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.xlarge};
  &:hover {
    color: ${props => props.theme.colors.CustomGreen};
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    padding: 10px 50px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    padding: 10px 30px;
  }
`

const ButtonClose = styled.button`
    border: none !important;
    display: flex;
    background-color: transparent;
    color: #000;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:hover{
      background-color: rgb(208, 211, 212, .5);
    };
    &:focus{
      outline: none;
      background-color: transparent;
    };
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    display: none;
  }
  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    width: 24px;
    height: 24px;
    padding: 16px;
  }
  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    width: 48px;
    height: 48px;
    padding: 0px;
  }
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-top: .8rem;
    font-size: 1em;
    width: 42px;
    height: 42px;
    padding: 0px;
  }
`

const WrapperMenu = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;
  font-size: 2.3rem;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    top: 50%;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    top: 45%;
    left: 2%;
    font-size: 1.9rem;
  }
`

export const Header = (props) => {
  const [sideMenuOpacity, setSideMenuOpacity] = useState(0);
  const [sideMenuHeight, setSideMenuHeight] = useState(0);

  const LogoClickHandler = () => {
    window.scrollTo(0, 0)
  }

  const OpenSideMenu = () => {
    console.log(sideMenuOpacity)
    if (sideMenuOpacity === 0) {
      setSideMenuOpacity(1)
      setSideMenuHeight(100)
    } else {
      setSideMenuOpacity(0)
      setSideMenuHeight(0)
    }
  }

  return (
    <Fragment>
      <Wrapper>
        <WrapperMenu>
          <ButtonClose onClick={OpenSideMenu}>
            {sideMenuOpacity === 0 ? <AiOutlineMenu /> : <AiOutlineCloseCircle />}
          </ButtonClose>
        </WrapperMenu>
        <SideMenu
          Opa={sideMenuOpacity}
          height={sideMenuHeight + '%'}
          onHideMenu={OpenSideMenu}
          knownUser={props.knownUser.request}
        />
        <LeftWrapper>
          <LogoHeader onClick={LogoClickHandler} src={Logo} alt="Graxalta" />
        </LeftWrapper>
        <RightWrapper>
          <MenuOption><Link to="about-us" spy={true} smooth={true} offset={-150}>Quiénes Somos</Link></MenuOption>
          <MenuOption><Link to="product" spy={true} smooth={true} offset={-150}>Productos</Link></MenuOption>
          {props.knownUser.request === 'customer' ? (
            <MenuOptionAnchor href="https://l.wl.co/l?u=https%3A%2F%2Fbit.ly%2FDeliveryGraxalta" target="_blank" rel="noreferrer">Pedidos</MenuOptionAnchor>
          ) : (<></>)}
          <MenuOption><Link to="contact-us" spy={true} smooth={true} offset={-150}>Contáctanos</Link></MenuOption>
        </RightWrapper>
      </Wrapper>
    </Fragment>
  )
}