import React from 'react'
import { Link } from 'react-scroll'
import styled from 'styled-components'
import ReactPixel from 'react-facebook-pixel';

const SideNav = styled.div`
  height: 100%; /* 100% Full-height */
  position: fixed; /* Stay in place */
  display: block;
  width: 80%;
  height: 0;
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: white;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px; /* Place content 60px from the top */
  transition: opacity 0.5s, height 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  opacity: 0;
  overflow: hidden;

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    margin-top: 5rem;
  }  

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-top: 93px;
  }
`;

const SideOption = styled.button`
  width: 100%;
  text-align: start;
  padding: 15px 8px 15px 32px;
  text-decoration: none;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts[0]};
  color: ${props => props.theme.colors.CustomGray};
  display: block;
  transition: 0.3s;
  border: none;
  background-color: transparent;
  &:hover{
    outline: none !important;
    background-color: ${props => props.theme.colors.CustomAqua};
  };
  &:focus:{
    outline: none !important;
    background-color: transparent;
  }

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 30px 0px 20px 50px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 30px 0px 20px 30px;
  }
`;

const SideOptionAnchor = styled.a`
  width: 100%;
  text-align: start;
  padding: 15px 8px 15px 32px;
  text-decoration: none;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts[0]};
  color: ${props => props.theme.colors.CustomGray};
  display: block;
  transition: 0.3s;
  border: none;
  background-color: transparent;
  &:hover{
    outline: none !important;
    background-color: ${props => props.theme.colors.CustomAqua};
  };
  &:focus:{
    outline: none !important;
    background-color: transparent;
  }

  /* MEDIUM SCREEN > TABLETS */
  @media (min-width: 768px) and (max-width: 1024px){
    padding: 30px 0px 20px 50px;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    padding: 30px 0px 20px 30px;
  }
`;

export const SideMenu = (props) => {

  const ButtonHandler = (e) => {
    if (e.target.id === 'whatsApp-btn') {
      ReactPixel.track('Contact', {
        content_name: "Side Menu"
      })
    }
    props.onHideMenu()
  }

  return (
    <SideNav style={{ opacity: props.Opa, height: props.height }}>
      <SideOption><Link to="about-us" spy={true} smooth={true} offset={-150} onClick={ButtonHandler}>Quiénes Somos</Link></SideOption>
      <SideOption><Link to="product" spy={true} smooth={true} offset={-150} onClick={ButtonHandler}>Productos</Link></SideOption>
      {props.knownUser === 'customer' ?
        (
          <SideOptionAnchor href="https://l.wl.co/l?u=https%3A%2F%2Fbit.ly%2FDeliveryGraxalta" target="_blank" rel="noreferrer" onClick={ButtonHandler}>Pedidos</SideOptionAnchor>
        )
        :
        (<></>)}
      <SideOption><Link to="contact-us" spy={true} smooth={true} offset={-150} onClick={ButtonHandler}>Contáctanos</Link></SideOption>
      <SideOptionAnchor href="https://wa.me/+584242236075" id='whatsApp-btn' target="_blank" rel="noreferrer" onClick={ButtonHandler}>WhatsApp</SideOptionAnchor>
    </SideNav>
  )
}