import { createGlobalStyle } from 'styled-components';

import FacebookSansRegular from './FacebookSansRegular.woff'
import FacebookSansBold from './FacebookSansBold.woff'

export default createGlobalStyle`
  @font-face {
    font-family: 'Facebook Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: url(${FacebookSansRegular}) format('woff');
  }

  @font-face {
    font-family: 'Facebook Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: url(${FacebookSansBold}) format('woff');
  }

  body{
    font-family: "Facebook Sans Regular";
    margin: 0px !important;
    
    ::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
`
