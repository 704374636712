import { Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-mui'

import Theme from './Theme/theme'
import GlobalFonts from './Fonts/fonts'
import history from './History'
import { Home } from './Views/Home'

import './App.css';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

function App() {
  return (
    <Theme>
      <AlertProvider template={AlertTemplate} {...options}>
        <Fragment>
          <GlobalFonts />
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Home} />
            </Switch>
          </Router>
        </Fragment>
      </AlertProvider>
    </Theme>
  );
}

export default App;
