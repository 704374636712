import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

import LeftAsset from '../Assets/Images/Web/graxalta_GxD_png_webdesign2021_blueleaf01.png'
import RightAsset from '../Assets/Images/Web/graxalta_GxD_png_webdesign2021_greenleaf01.png'
import HomeVideo from '../Assets/Videos/Web/graxalta_VES_mp4_webdesign2021_headeranimation_02.mp4'
import HomeVideoMobile from '../Assets/Videos/Mobile/graxalta_VES_webdesign2021_headeranimation_responsive_01.mp4'

const Wrapper = styled.div`
  position: relative;
  margin-top: 180px;
  width: auto;
  padding: 50px 0;
  background-color: ${props => props.theme.colors.CustomWhite};

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-top: 90px;
    padding: 10px 0;
  }
`

const LeftImg = styled.img`
  position: absolute;
  width: 273px;
  height: 240px;
  top: 0;
  left: 0;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`

const RightImg = styled.img`
  position: absolute;
  width: 374px;
  height: 374px;
  bottom: -50px;
  right: 0;
  
  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    display: none;
  }
`

export const VideoSection = () => {
  const [video, setVideo] = useState(HomeVideo)

  const updateWindowDimensions = () => {
    if (window.innerWidth < 760)
      setVideo(HomeVideoMobile)
  }

  useEffect(() => {
    console.log('Activando Resize')
    window.addEventListener("resize", updateWindowDimensions());
    return () => {
      console.log('Desactivando Resize')
      window.removeEventListener('resize', updateWindowDimensions());
    };
  }, []);

  return (
    <Wrapper>
      <LeftImg src={LeftAsset} alt="Decoracion Izquierda" />
      <RightImg src={RightAsset} alt="Decoracion Derecha" />
      <ReactPlayer
        url={video}
        loop={true}
        playing={true}
        width='100%'
        height='100%'
        muted={true}
      />
    </Wrapper>
  )
}