import firebase from '../firebase'
import uuid from 'react-uuid'

var database = firebase.database();

class GraxaltaServices {

  async addSubscriber(email, allowNotifications, entity) {
    await firebase.auth().signInAnonymously();
    var userId = uuid();
    firebase.database().ref('users/' + userId).set({
      email,
      subscription: allowNotifications || false,
      type: entity
    });
  }

  async addForm(name, company, email, type, product, description) {
    await firebase.auth().signInAnonymously();
    console.log(name, company, email, type, product, description)
    database.ref("/users").once('value').then(function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();

        if (childData.email === email) { //EL USUARIO YA EXISTE
          firebase.database().ref('users/' + childKey).update({
            name,
            company,
            product,
            description
          });
        } else { //USUARIO NUEVO
          var userId = uuid();
          firebase.database().ref('users/' + userId).set({
            name,
            email,
            company,
            product,
            description,
            type
          });
        }
        return true;
        // let data = JSON.parse(JSON.stringify(childData))
        // data['id'] = childKey
        // listReturn.push(data)
      });
      // return listReturn
    }).catch((e) => {
      console.log("Error", e);
    });
  }

  async getNews() {
    await firebase.auth().signInAnonymously();

    let listReturn = [];

    return database.ref("/News").orderByChild('Date').once('value').then(function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        let data = JSON.parse(JSON.stringify(childData))
        data['id'] = childKey
        listReturn.push(data)
      });
      return listReturn
    }).catch((e) => {
      console.log("Error", e);
    });
  }
}

export default new GraxaltaServices();