import React, { useReducer, useRef } from 'react'
import styled from 'styled-components'
import ReCAPTCHA from "react-google-recaptcha"
import GraxServices from '../Services/graxalta-services'
import { useAlert } from 'react-alert'
import emailjs from 'emailjs-com';

const EmailJSServiceID = 'graxalta_contact';
const EmailJSTempateID = 'graxalta_contact_form';


const Wrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  background-color: ${props => props.theme.colors.CustomWhite};
`

const WrapperTitle = styled.div`
  display: inherit;
  height: 80px;
  align-items: center;
  justify-content: center;  

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (min-width: 1025px){
    margin-bottom: 20px;
  }

  /*BIG SCREEN > LAPTOPS / DESKTOPS */
  @media (max-width: 1425px){
    margin-bottom: 0px;
  }
`

const Title = styled.div`
  font-size: ${props => props.theme.fontSizes.xlarge};
  font-family: ${props => props.theme.fonts[1]};
  color: ${props => props.theme.colors.CustomBlue};
  padding: 0 10px;
`

const GreenLine = styled.div`
  border-top: 2px solid ${props => props.theme.colors.CustomGreen};
  width: 40px;
  margin-bottom: 3px;
`

// const WrapperContactInfo = styled.div`
//   font-size: 12px;
//   color: ${props => props.theme.colors.CustomGray};
//   text-align: center;
//   margin-top: 30px;
//   margin-bottom: 80px;
// `

const WrapperForm = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column; 
  margin: 40px 0;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 80%;
  }
`

const InputForm = styled.input`
  width: auto;
  height: 40px;
  margin-bottom: 10px;
  border: none;
  border-radius: 12px;
  outline: none;
  padding-left: 15px;
  font-family: ${props => props.theme.fonts[0]};
  background-color: ${props => props.theme.colors.CustomSkyBlue};

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: auto;
  }
`

const TextareForm = styled.textarea`
  width: auto;
  height: 100px;
  margin-bottom: 10px;
  border: none;
  border-radius: 12px;
  outline: none;
  padding: 10px 15px;
  resize: none;
  font-family: ${props => props.theme.fonts[0]};
  background-color: ${props => props.theme.colors.CustomSkyBlue};

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: auto;
  }
`

const SelectForm = styled.select`
  /* reset */
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* reset */
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border: none;
  border-radius: 12px;
  outline: none;
  padding-left: 15px;
  font-family: ${props => props.theme.fonts[0]};
  background-color: ${props => props.theme.colors.CustomSkyBlue};
  &:invalid { color: ${props => props.theme.colors.CustomGray}; }
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  &:focus {
    background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: gray;
    outline: 0;
  }

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: auto;
  }
`

const LabelInputForm = styled.span`
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.CustomGray};
  text-align: left;
  padding: 10px 0;
`

const WrapperButton = styled.div`
  width: 103%;
  text-align: end;
  margin-top: 70px;  
  margin-bottom: 80px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: auto;
    margin-top: 50px;
    text-align: center;
  }
`

const ButtonForm = styled.button`
  width: 170px;
  height: 60px;
  font-size: ${props => props.theme.fontSizes.large};
  border: none;
  border-radius: 12px;
  outline: none;
  padding: 10px 15px;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000024;
  color: ${props => props.theme.colors.CustomBlue};
  background-color: ${props => props.theme.colors.CustomSkyBlue};
  font-family: ${props => props.theme.fonts[0]};

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 100%;
  }
`

const formReducer = (state, event) => {
  if (event.reset) {
    return {
      contactMail: '',
      name: '',
      company: '',
      request: '',
      products: '',
      description: ''
    }
  }

  return {
    ...state,
    [event.name]: event.value
  }
}

export const ContactSection = (props) => {
  const refCaptcha = useRef();
  const alert = useAlert()

  const [formData, setFormData] = useReducer(formReducer, {
    contactMail: "",
    request: props.knownUser.request
  });

  const HandlerSumbitForm = (e) => {
    e.preventDefault()

    let selectedProduct
    switch (formData.products) {
      case 'cheese1':
        selectedProduct = 'Quesos de Bufala'
        break
      case 'cheese2':
        selectedProduct = 'Quesos de Cabra'
        break
      case 'cheese3':
        selectedProduct = 'Quesos'
        break
      case 'mushrooms':
        selectedProduct = 'Hongos Cultivados'
        break
      case 'fruits':
        selectedProduct = 'Frutas Congeladas'
        break
      default:
        selectedProduct = 'Otros'
        break
    }

    let selectedRequest = formData.request === undefined ? props.knownUser.request : formData.request
    switch (selectedRequest) {
      case 'supplier':
        selectedRequest = 'Proveedor'
        break
      case 'wholesaler':
        selectedRequest = 'Comercial'
        break
      case 'customer':
        selectedRequest = 'Delivery'
        break
      default:
        selectedRequest = 'Otros'
        break
    }

    const recaptchaValue = refCaptcha.current.getValue();
    if (!recaptchaValue) {
      alert('Debe completar el reCAPTCHA')
      return
    }

    var templateParams = {
      user_name: formData.name,
      company_name: formData.company,
      contact_email: formData.contactMail,
      request_type: selectedRequest,
      product: selectedProduct,
      description: formData.description
    };


    GraxServices.addForm(formData.name, formData.company, formData.contactMail, formData.request, formData.products, formData.description).then(() => {
      console.log("Ok...");
      refCaptcha.current.reset()
      setFormData({
        reset: true
      })
      alert.show('Tu información ha sido enviada exitosamente, pronto nos comunicaremos con usted', {
        closeCopy: "Aceptar",
      })

      emailjs.send(EmailJSServiceID, EmailJSTempateID, templateParams, 'user_cICzPprKACVYmfJbXCj5j')
        .then(function (response) {
          console.log('SUCCESS!', response.status, response.text);
        }, function (err) {
          console.log('FAILED...', err);
        });
    }).catch((e) => {
      console.log(e);
    });
  }

  const HandlerChangeData = (e) => {
    setFormData({
      name: e.target.name,
      value: e.target.value,
    })
  }

  return (
    <Wrapper id="contact-us">
      <WrapperTitle>
        <GreenLine />
        <Title>Contáctanos</Title>
        <GreenLine />
      </WrapperTitle>
      <WrapperForm onSubmit={HandlerSumbitForm}>
        <LabelInputForm>Nombre</LabelInputForm>
        <InputForm id="name" name="name" type="text" value={formData.name || ''} required onChange={HandlerChangeData} />
        <LabelInputForm>Compañía (opcional)</LabelInputForm>
        <InputForm id="company" name="company" type="text" value={formData.company || ''} onChange={HandlerChangeData} />
        <LabelInputForm>Correo</LabelInputForm>
        <InputForm id="contactMail" name="contactMail" type="email" required value={props.knownUser.mail || formData.contactMail} onChange={HandlerChangeData} />
        <LabelInputForm>Solicitud</LabelInputForm>
        <SelectForm name="request" id="request" value={props.knownUser.request || formData.request} onChange={HandlerChangeData} required >
          <option value="">--Seleccione una opción--</option>
          <option value="supplier">Proveedor</option>
          <option value="wholesaler">Comercio</option>
          <option value="customer">Delivery/Pick-up</option>
        </SelectForm>
        <LabelInputForm>Producto (opcional)</LabelInputForm>
        <SelectForm name="products" id="products" value={formData.products || ''} onChange={HandlerChangeData} required  >
          <option value="">--Seleccione una opción--</option>
          <option value="cheese1">Quesos de Bufala</option>
          <option value="cheese2">Quesos de Cabra</option>
          <option value="cheese3">Quesos</option>
          <option value="mushrooms">Hongos Cultivados</option>
          <option value="fruits">Frutas Congeladas</option>
          <option value="others">Otros</option>
        </SelectForm>
        <LabelInputForm>Descripción de la Solicitud</LabelInputForm>
        <TextareForm id="description" name="description" value={formData.description || ''} required onChange={HandlerChangeData} />
        <center>
          <ReCAPTCHA ref={refCaptcha} style={{ marginTop: '20px' }} sitekey="6LdXNkAaAAAAAPAilIQm7o4wUnFPdYAfXxjOOH90" />
        </center>
        <WrapperButton>
          <ButtonForm type="submit">Enviar</ButtonForm>
        </WrapperButton>
      </WrapperForm>
    </Wrapper>
  )
}