import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyBVJwAnImji47KUTM7Njjc055acgFsEhHE",
  authDomain: "graxalta-8a804.firebaseapp.com",
  projectId: "graxalta-8a804",
  storageBucket: "graxalta-8a804.appspot.com",
  messagingSenderId: "648514246890",
  appId: "1:648514246890:web:1c6c6ffe478d83b7709f93",
  measurementId: "G-HTHE4EBXK9"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;