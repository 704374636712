import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.theme.colors.CustomWhite};
`

const WrapperTitle = styled.div`
  display: inherit;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    margin-bottom: 10px;
  }
`

const Title = styled.div`
  font-size: ${props => props.theme.fontSizes.xlarge};
  font-family: ${props => props.theme.fonts[1]};
  color: ${props => props.theme.colors.CustomBlue};
  padding: 0 10px;
`

const GreenLine = styled.div`
  border-top: 2px solid ${props => props.theme.colors.CustomGreen};
  width: 40px;
  margin-bottom: 3px;
`

const Content = styled.div`
  width: 50%;
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.CustomGray};

  /* SMALL SCREEN > MOBILE */
  @media (max-width: 760px){
    width: 80%;
  }
`

const LinkText = styled.span`
  color: ${props => props.theme.colors.CustomBlue};
  font-size: ${props => props.theme.fontSizes.small};
  font-family: ${props => props.theme.fonts[1]};
  text-decoration: none;
  cursor: pointer;
`

const LinkAnchor = styled.a`
  color: ${props => props.theme.colors.CustomBlue};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.medium};
  text-decoration: none;
  cursor: pointer;
`

export const AboutSection = (props) => {
  const [display, setDisplay] = useState(<></>)

  useEffect(() => {
    switch (props.knownUser.request) {
      case 'supplier':
        setDisplay(
          <>
            <LinkText style={{fontSize: '14px'}}><Link to="contact-us" smooth={true} offset={-150}>{' '}Contáctenos para más información.</Link></LinkText>
          </>
        )
        break;
      case 'wholesaler':
        setDisplay(
          <>
            <LinkAnchor href="https://l.wl.co/l?u=https%3A%2F%2Fbit.ly%2FDeliveryGraxalta" target="_blank" rel="noreferrer">Contáctenos para más información.</LinkAnchor>
          </>
        )
        break;
      case 'customer':
        setDisplay(
          <>
            <LinkText><Link to="contact-us" smooth={true} offset={-150}>{' '}Contáctenos para más información.</Link></LinkText>
          </>
        )
        break;
      default:
        break;
    }
  }, [props.knownUser.request])

  return (
    <Wrapper id="about-us">
      <WrapperTitle>
        <GreenLine />
        <Title>Quiénes Somos</Title>
        <GreenLine />
      </WrapperTitle>
      <Content>
        Somos una empresa familiar que desde hace 30 años se ha dedicado a la distribución de alimentos perecederos en la zona metropolitana de Caracas.
        Graxalta distribuye Hongos cultivados, Lácteos y Frutas congeladas.
        Tenemos como política suplirnos directamente de productores (nacionales e internacionales), para así ofrecer una alta calidad y frescura de los productos, y lo que nos permite asegurar una alta disponibilidad de productos y ofrecer precios competitivos.
      </Content>
      <br />
      <Content>
        Estamos ubicados en la zona Este de Caracas, muy cercanos a nuestros clientes.
        Parte nuestra clientela abarca Automercados, Restaurantes, Food trucks, Bodegones y Fruterías.
        {' '}
        {display}
      </Content>
    </Wrapper>
  )
}