import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    CustomWhite: "#FFFFFF",
    CustomGray: "#707070",
    CustomGreen: "#A3BA56",
    CustomBlue: "#012340",
    CustomSkyBlue: "#EDF1F4"
  },
  fonts: ["Facebook Sans Regular", "Facebook Sans Bold", "Roboto"],
  fontSizes: {
    small: "12px",
    medium: "14px",
    large: "16px",
    xlarge: "18px"
  }
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;